// eslint-disable-next-line no-restricted-imports
import { OptionsObject, ProviderContext, SnackbarKey, useSnackbar } from "notistack";
import { isValidElement, ReactElement, Ref, useCallback } from "react";
import { Snackbar, SnackbarOptionsProps } from "../components/Snackbar";

export type UseNotificationsReturnType = {
  sendNotification: (
    snackbarOptions:
      | SnackbarOptionsProps
      | ReactElement<{ ref: Ref<HTMLElement> }>
      | ((close: () => void) => ReactElement<{ ref: Ref<HTMLElement> }>),
    notistackOptions?: OptionsObject
  ) => () => void;
};

export const useNotifications = (): UseNotificationsReturnType => {
  // useNotifications returns undefined in SSR
  const { enqueueSnackbar, closeSnackbar } = (useSnackbar?.() as ProviderContext | undefined) || {};

  const sendNotification = useCallback<UseNotificationsReturnType["sendNotification"]>(
    (snackbarOptions, notistackOptions) => {
      let id: SnackbarKey;
      const handleClose = () => closeSnackbar?.(id);

      id =
        enqueueSnackbar?.("", {
          content: (() => {
            if (typeof snackbarOptions === "function") return snackbarOptions(handleClose);
            else if (isValidElement(snackbarOptions)) return snackbarOptions;
            else return <Snackbar onClose={handleClose} {...snackbarOptions} />;
          })(),
          ...notistackOptions,
        }) || "";

      return handleClose;
    },
    [closeSnackbar, enqueueSnackbar]
  );

  return { sendNotification };
};
